export default [
  // {
  //   name: "sceneModel",
  //   type: "gltfModel",
  //   path: "/models/scene/scene.glb",
  // },
  // {
  //   name: "columnsModel",
  //   type: "gltfModel",
  //   path: "/models/columns/columns.glb",
  // },
  // {
  //   name: "bakedColumnsTexture",
  //   type: "texture",
  //   path: "/models/columns/baked.png",
  // },
  {
    name: "roundModel",
    type: "gltfModel",
    path: "/models/round/round.glb",
  },
  {
    name: "bakedRoundTexture",
    type: "texture",
    path: "/models/round/baked_compressed_high.jpg",
  },
];
